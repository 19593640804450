import Op from './Op'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

class Pull extends Op {
  static run ({ value, op }) {
    const opValue = get(op, 'value')
    if (!value || !Array.isArray(value)) {
      return value
    }
    if (Array.isArray(opValue)) {
      return value.filter(v => !opValue.includes(v))
    } else {
      return value.filter(v => !isEqual(v, opValue))
    }
  }

  static shouldRun ({ value, op }) {
    if (!Array.isArray(value)) {
      return false
    }
    const opValue = get(op, 'value')
    if (Array.isArray(opValue)) {
      return value.filter(v => opValue.includes(v)).length > 0
    } else {
      return !!value.find(v => isEqual(v, opValue))
    }
  }
}

export default Pull
