import Model from './Model'

class Sequence extends Model {
  static entity = 'sequences'

  static evictUnused = true

  static extendedBy = ['blasts']

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      archived: this.attr(undefined),
      name: this.attr(undefined),
      type: this.attr(undefined),
      class: this.attr(undefined),
      channels: this.attr(undefined),
      status: this.attr(undefined),
      last_run_at: this.attr(undefined),
      next_run_at: this.attr(undefined),
      active: this.attr(undefined),
      meta: this.attr(undefined),
      stats: this.attr(undefined),
      permissions: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default Sequence
