import Trigger from '../Trigger'

class OrderFinished extends Trigger {
  static get title () {
    return 'Order Finished'
  }

  static get class () {
    return 'OrderFinished'
  }

  static get restrict () {
    return { 'addons.type': { $in: ['ORDERS', 'NCRSILVER', 'REVEL'] } }
  }

  // These are deprecated in favor of shared/schemas/events/*
  get schemas () {
    return [
      { key: 'client_id', name: 'Client ID', type: 'text' },
      { key: 'total_amount', name: 'Total Price (in cents)', type: 'number' }
    ]
  }
}

export default OrderFinished
