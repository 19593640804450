import { get } from 'lodash'

export default ({ account = {} } = {}) => {
  return {
    'content.text': { name: 'Message Text', type: 'text' },
    tags: {
      name: 'Message Tags',
      type: 'text',
      multiple: true,
      options: (get(account, 'settings.message_tags') || []).map(t => ({
        ...t,
        label: t.text || t.value
      }))
    },
    channel: {
      name: 'Channel',
      type: 'text',
      options: [
        { label: 'SMS', value: 'SMS' },
        { label: 'Email', value: 'EMAIL' },
        { label: 'Chat', value: 'CHAT' }
      ]
    },
    chat_url: { name: 'Page URL', type: 'text' },
    from: { name: 'From (phone #)', type: 'text' },
    to: { name: 'To (phone #)', type: 'text' },
    conversation_id: { name: 'Conversation ID', type: '_id' },
    keyword_id: { name: 'Triggered Keyword', type: 'number', resource: 'keywords' },
    auto_reply_id: { name: 'Triggered Auto Reply', type: '_id', resource: 'auto_replies' },
    gateway_id: { name: 'Gateway', type: 'number', resource: 'gateways' }
  }
}
