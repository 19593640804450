export default (load) => {
  return [
    {
      path: '/:accountId/settings',
      component: load(() => import('@/modules/Settings')),
      meta: {
        name: 'Settings', // navigable `name`/ID of default child
        label: 'Settings', // user facing text for this route
        icon: 'fa-cog'
      },
      children: [
        {
          name: 'Settings',
          path: '', // the default child route
          component: load(() => import('@/modules/Settings/MainMenu'))
        },
        {
          name: 'AccountSettings',
          path: '/:accountId/settings/account',
          component: load(() => import('@/modules/Settings/Account')),
          meta: {
            label: 'Account Details'
          }
        },
        {
          name: 'APISettings',
          path: '/:accountId/settings/api',
          component: load(() => import('@/modules/Settings/Api')),
          meta: {
            label: 'API Access',
            includesTopBar: true
          }
        },
        {
          name: 'BillingSettings',
          path: '/:accountId/settings/billing',
          component: load(() => import('@/modules/Settings/Billing')),
          meta: {
            label: 'Billing',
            includesTopBar: true
          }
        },
        {
          name: 'EmailSettings',
          path: '/:accountId/settings/email',
          component: load(() => import('@/modules/Settings/Email')),
          meta: {
            label: 'Email Settings'
          }
        },
        {
          path: '/:accountId/settings/email-snippets',
          component: load(() => import('@/modules/Settings/EmailSnippets')),
          meta: {
            name: 'EmailSnippetSettings', // navigable `name`/ID of default child
            label: 'Email Snippets', // user facing text for this route
            fullWidth: true,
            includesTopBar: true
          },
          children: [
            {
              name: 'EmailSnippetEditor',
              path: ':recordId',
              component: load(() => import('@/modules/Settings/EmailSnippets/EmailSnippetEditor')),
              meta: {
                label: 'Email Snippet Editor',
                fullWidth: true,
                includesTopBar: true
              }
            },
            {
              name: 'EmailSnippetSettings',
              path: '', // default child route
              component: load(() => import('@/modules/Settings/EmailSnippets/EmailSnippetList')),
              meta: {
                label: 'Email Snippets',
                fullWidth: true,
                includesTopBar: true,
                fullHeight (vm) {
                  return true
                }
              }
            }
          ]
        },
        {
          name: 'FieldSettings',
          path: '/:accountId/settings/fields',
          component: load(() => import('@/modules/Settings/Fields')),
          meta: {
            label: 'Fields',
            fullWidth: true,
            includesTopBar: true
          }
        },
        {
          name: 'FileSettings',
          path: '/:accountId/settings/files',
          component: load(() => import('@/modules/Settings/Files')),
          meta: {
            label: 'File Manager',
            fullWidth: true,
            fullHeight: true,
            noExtension: true
          }
        },
        {
          name: 'FilterSettings',
          path: '/:accountId/settings/segments',
          component: load(() => import('@/modules/Settings/Filters')),
          meta: {
            label: 'Segments',
            fullWidth: true,
            includesTopBar: true
          }
        },
        {
          name: 'GroupSettings',
          path: '/:accountId/settings/groups',
          component: load(() => import('@/modules/Settings/Groups')),
          meta: {
            label: 'Groups',
            fullWidth: true,
            includesTopBar: true
          }
        },
        {
          name: 'ImportSettings',
          path: '/:accountId/settings/imports',
          component: load(() => import('@/modules/Settings/Imports')),
          meta: {
            label: 'Imports',
            includesTopBar: true
          }
        },
        {
          name: 'LoyaltySettings',
          path: '/:accountId/settings/loyalty',
          component: load(() => import('@/modules/Settings/Loyalty'))
        },
        {
          path: '/:accountId/revel',
          name: 'RevelSettings',
          component: load(() => import('@/modules/Settings/Revel')),
          meta: {
            name: 'Revel Settings'
          }
        },
        {
          name: 'RfmSettings',
          path: '/:accountId/settings/rfm',
          component: load(() => import('@/modules/Settings/Rfm')),
          meta: {
            label: 'RFM Settings'
          }
        },
        {
          name: 'SmsSettings',
          path: '/:accountId/settings/sms',
          component: load(() => import('@/modules/Settings/Sms')),
          meta: {
            includesTopBar: true
          }
        },
        {
          name: 'ShopifySettings',
          path: '/:accountId/settings/shopify',
          component: load(() => import('@/modules/Settings/Shopify')),
          meta: {
            label: 'Shopify Settings'
          }
        },
        {
          path: '/:accountId/settings/templates',
          component: load(() => import('@/modules/Settings/Templates')),
          meta: {
            name: 'TemplateSettings', // navigable `name`/ID of default child
            label: 'Templates', // user facing text for this route
            fullWidth: true,
            includesTopBar: true,
            fullHeight (vm) {
              return vm.$vuetify.breakpoint.mdAndUp
            }
          },
          children: [
            {
              name: 'TemplateEditor',
              path: ':recordId',
              component: load(() => import('@/modules/Settings/Templates/TemplateEditor')),
              meta: {
                label: 'Template Editor',
                fullWidth: true,
                includesTopBar: true
              }
            },
            {
              name: 'TemplateSettings',
              path: '', // default child route
              component: load(() => import('@/modules/Settings/Templates/TemplateList')),
              meta: {
                label: 'Template Settings',
                fullWidth: true,
                includesTopBar: true,
                fullHeight (vm) {
                  return true
                }
              }
            }
          ]
        },
        {
          name: 'UsageSettings',
          path: '/:accountId/settings/usage',
          component: load(() => import('@/modules/Settings/Usage')),
          meta: {
            includesTopBar: true
          }
        },
        {
          name: 'WebPushSettings',
          path: '/:accountId/settings/web-push',
          component: load(() => import('@/modules/Settings/WebPush')),
          meta: {
            label: 'Web Push Notifications'
          }
        },
        {
          name: 'WordpressSettings',
          path: '/:accountId/settings/wordpress',
          component: load(() => import('@/modules/Settings/Wordpress')),
          meta: {
            label: 'Wordpress / WooCommerce Settings'
          }
        },
        {
          name: 'GeneralFieldSettings',
          path: '/:accountId/settings/general-fields',
          component: load(() => import('@/modules/Settings/GeneralFields')),
          meta: {
            includesTopBar: true
          }
        }
      ]
    }
  ]
}
