<template>
  <v-card
    class="pa-3"
  >
    <div class="mb-2">
      <strong>Usage Limits</strong>
    </div>
    <v-checkbox
      label="Limit To One Use Per Customer"
      hide-details
      :input-value="value"
      readonly
      @click="!disabled && $emit('input', !value)"
      v-bind="{ disabled }"
    />
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
