import { pickBy } from 'lodash'

/*
  Accepts an object of schemas and a path.  Generally returns schemas[path]
    - If referencing a value on a date or time field (v/y/m/d) or (v/h/m/s) a "fake"
      schema will be returned since data is stored like that in the db, as defined in
      impliedSchemas.
    - Schemas are now expected to be an object keyed by the path of the schema as
      defined in shared/schemas
*/
const schemaForPath = function (schemas = {}, path) {
  var impliedSchemas = {
    date: {
      v: { name: 'Value', type: 'text' },
      y: { name: 'Year', type: 'number', cast: 'int' },
      m: { name: 'Month', type: 'number', cast: 'int' },
      d: { name: 'Day', type: 'number', cast: 'int' }
    },
    time: {
      v: { name: 'Value', type: 'text' },
      h: { name: 'Hour', type: 'number', cast: 'int' },
      m: { name: 'Minute', type: 'number', cast: 'int' }
      // s: { name: 'Second', type: 'number', cast: 'int' } // Seconds aren't really important
    }
  }

  const initialPath = path

  // Ignore indexes in the path (such as contact.widgets[2].name)
  path = path.replace(/\[\d*\]/gi, '')

  // If it's a regular field then just return it
  if (schemas?.[path]) {
    const resultSchema = {
      path,
      ...schemas[path],
      // If the path ends in an array index then remove "multiple" from the schema
      ...(/.*\[\d+\]$/.test(initialPath) && schemas[path].multiple ? { multiple: false } : {})
    }

    if (resultSchema.type === 'object') {
      const subSchemas = pickBy(schemas, (_schema, _path) => {
        return _path.startsWith(path) && _path !== path
      })
      if (Object.entries(subSchemas).length) {
        resultSchema.schemas = {
          ...(resultSchema.schemas || {})
        }
        Object.entries(subSchemas).forEach(([_path, _schema]) => {
          resultSchema.schemas[_path.replace(path + '.', '')] = _schema
        })
      }
    }

    return Object.freeze(resultSchema)
  }

  // If it's a date/time field attempt to locate the parent date/time field by
  // removing v/y/m/d/h/s from the end of the path
  const pathParts = path.split('.')
  if (pathParts.length > 1 && ['v', 'y', 'm', 'd', 'h', 's'].includes(pathParts[pathParts.length - 1])) {
    const possiblePathOfDateOrTime = pathParts.slice(0, pathParts.length - 1).join('.')
    const possibleDateOrTime = schemas?.[possiblePathOfDateOrTime]
    if (possibleDateOrTime && ['date', 'time'].includes(possibleDateOrTime.type)) {
      const impliedSchema = impliedSchemas[possibleDateOrTime.type][pathParts[pathParts.length - 1]]
      return Object.freeze({
        ...impliedSchema,
        parent: possiblePathOfDateOrTime,
        path,
        full_name: `${possibleDateOrTime.name} ${impliedSchema.name}`
      })
    }
  }
}

export default schemaForPath
