<template>
  <div>
    <div
      class="text-center"
      style="font-size: 30px; line-height: 34px"
    >
      <strong>Welcome to Patch!</strong>
    </div>
    <div
      class="text-center mt-2 mb-6"
      style="font-size: 16px; line-height: 18px;"
    >Let's sync your data.</div>
    <v-card
      class="pa-7 pt-4"
    >
      <v-btn
        icon
        @click="$emit('back')"
        v-bind="{ disabled }"
      >
        <v-icon>fa fa-arrow-left</v-icon>
      </v-btn>
      <br />
      <p>You can migrate all of your existing {{ addonTypeName }} Orders into your account for actionable analysis.</p>
      <p>The sync process can take several hours to complete. Click the Begin Sync button below to initiate a sync.</p>
      <p><strong>Configure Rules</strong>
      <v-tooltip
        top
        :max-width="300"
      >
        <template v-slot:activator="{ on }">
          <v-icon
            class="ml-1 mb-2"
            v-on="on"
            x-small
          >fa-exclamation-circle</v-icon>
        </template>
        <span>Contacts and their data will only be included in the results if data fields set here match.</span>
      </v-tooltip></p>
      <p>Use the filter options available here to prevent contacts from being created in your account based on the customers' data in {{ addonTypeName }}. Customers will only be created in your account if they match all rules added below.*</p>
      <p>Note: Existing contacts will continue to sync, regardless of the filter settings. To prevent existing contacts from syncing, delete these contacts from the Contacts section.</p>
      <v-checkbox
        class="pb-0"
        @change="$handleChange('record', 'config.filter', $event ? null : {})"
        hide-details
        :input-value="!(recordChanged && recordChanged.config && recordChanged.config.filter)"
        label="Leave this box checked to include ALL customers"
        :disabled="disabled"
      />
      <template
        v-if="recordChanged && recordChanged.config && recordChanged.config.filter"
      >
        <br />
        <filter-editor
          :schemas="customerSchemas"
          :value="recordChanged.config.filter"
          @input="$handleChange('record', 'config.filter', $event)"
          outlined
          dense
          :disabled="disabled"
        />
      </template>
      <div
        class="text-right mt-3"
      >
        <p style="text-align: left; opacity: 0.6;" class="mb-0">*These inclusions can be changed at a later time within your settings.</p>
        <p style="text-align: left; opacity: 0.6;">Please note: {{ addonTypeName }} inclusions ARE case-sensitive.</p>
        <v-btn
          color="warning"
          class="mr-4"
          outlined
          @click="save()"
          :disabled="disabled"
        >Continue Without Syncing</v-btn>
        <v-btn
          color="primary"
          @click="saveAndSync()"
          :disabled="disabled"
        >
          Begin Sync
        </v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import { Addon } from 'ui/models'
import { customerSchemas as shopifyCustomerSchemas } from 'shared/schemas/addons/shopify'
import { customerSchemas as wordpressCustomerSchemas } from 'shared/schemas/addons/wordpress'
import FilterEditor from 'ui/components/Filter'

export default {
  mixins: [
    createChangeTrackerMixin({ path: 'record' })
  ],
  components: {
    FilterEditor
  },
  props: {
    account: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSyncing: false
    }
  },
  computed: {
    addonTypeName () {
      if (this.record?.type === 'SHOPIFY') {
        return 'Shopify'
      } else if (this.record?.type === 'WORDPRESS') {
        return 'Woo Commerce'
      }
      return null
    },
    addonId () {
      return (this.record && this.record._id) || null
    },
    customerSchemas () { // Return array
      const customerSchemas = {
        SHOPIFY: shopifyCustomerSchemas,
        WORDPRESS: wordpressCustomerSchemas
      }[this.record?.type] || {}
      return Object.entries(customerSchemas)
        .filter(([path, schema]) =>
          !schema.unselectable &&
          !schema.hidden // Remove any "hidden" fields
        )
        .map(([path, schema]) => ({ ...schema, path }))
    },
    record () {
      return Addon.query()
        .where(a => ['WORDPRESS', 'SHOPIFY'].includes(a.type))
        .first()
    }
  },
  methods: {
    ...mapActions({
      syncShopifyData: 'shopify/syncData',
      syncWordpressData: 'wordpress/syncData'
    }),
    async save () {
      await Addon.save(this.recordChanged)
      this.$clearChanges()
      await this.$emit('save', this.isSyncing)
    },
    async saveAndSync () {
      this.isSyncing = true
      await this.save()
      if (this.record?.type === 'SHOPIFY') {
        await this.syncShopifyData()
      } else if (this.record?.type === 'WORDPRESS') {
        await this.syncWordpressData()
      }
    }
  }
}
</script>
