<template>
  <v-app
    :class="{ small: $vuetify.breakpoint.smAndDown }"
    :style="{ backgroundColor: 'transparent' }"
  >
    <auth
      v-if="requireLogin"
    />
    <new-password
      v-else-if="requireNewPassword"
    />
    <create-account
      v-else-if="createAccount"
    />
    <failed-charge
      v-else-if="failedCharge"
    />
    <div
      v-else
      class="application-container"
      :class="{ 'o-window-active': oWindowCount > 0 }"
    >
      <left-navigation
        v-if="$root.routerInitialized && !requireLogin && !createAccount"
      />
      <v-main
        v-if="user && !appLoading"
      >
        <sync-header
          v-if="syncHeaderError"
        />

        <div
          v-if="roadblockComponent"
          class="roadblock-container fill-height"
        >
          <div
            style="max-width: 750px; margin: 0 auto;"
            :class="{ 'px-2 pt-10': $vuetify.breakpoint.smAndUp }"
          >
            <component
              :is="roadblockComponent"
            />
          </div>
        </div>
        <router-view
          v-else-if="!createAccount"
        />
      </v-main>
    </div>
    <o-windows-container
      v-if="$root.routerInitialized"
      @oWindowCountUpdated="oWindowCount = $event"
    />
    <v-alert
      v-if="appError"
      color="error"
      class="app-error"
      dismissible
      dark
      @input="$store.commit('setAppError', null)"
    >
      {{ appError }}
    </v-alert>
    <transition name="fade">
      <v-overlay
        v-show="appLoading"
        z-index="100000"
      >
        <v-progress-circular
          size="40"
          indeterminate
          color="white"
        ></v-progress-circular>
      </v-overlay>
    </transition>
    <!-- Prevent body scroll when window/nav is open -->
    <v-style v-if="$vuetify.breakpoint.mdAndDown && (oWindowCount > 0 || appDrawerLeft)">
      html,
      body {
        overflow: hidden !important;
      }
    </v-style>
    <v-style>
      body {
        background-color: {{ $vuetify.theme.dark ? '#121212': 'rgba(0, 0, 0, 0.05)' }};
      }
    </v-style>
    <v-style
      v-if="!$root.isIOS"
    >
      body {
        width: 100%;
        height: 100%;
        overflow: auto;
      }
    </v-style>
    <!-- If current route requires full-height ability for inner scroll -->
    <v-style v-if="fullHeight">
      html,
      body,
      div.v-application,
      div.v-application--wrap,
      div.application-container,
      main.v-main {
        height: 100%;
        overflow: auto;
      }
    </v-style>
  </v-app>
</template>

<style lang="scss">
.v-alert.app-error {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
}
.application-container {
  &.o-window-active {
    opacity: 0.6;
  }
}
</style>

<script>
import Auth from 'ui/components/Auth'
import NewPassword from 'ui/components/Auth/NewPassword.vue'
import CreateAccount from '@/components/CreateAccount'
import FailedCharge from '@/components/FailedCharge'
import LeftNavigation from '@/components/LeftNavigation'
import SyncHeader from '@/components/CreateAccount/SyncHeader'
import { mapState, mapGetters } from 'vuex'
// import load from '@/router/load' // uncomment if we want to go back to an opt in/expired trial version of onboarding

export default {
  name: 'App',
  components: {
    Auth,
    NewPassword,
    CreateAccount,
    FailedCharge,
    LeftNavigation,
    SyncHeader
  },
  data () {
    return {
      oWindowCount: 0
    }
  },
  computed: {
    ...mapState([
      'requireLogin',
      'appError',
      'appLoading',
      'appDarkMode',
      'appDrawerLeft'
    ]),
    ...mapState({
      roadblockUi: state => state.roadblock.roadblockUi
    }),
    ...mapGetters(['user', 'account']),
    ...mapGetters({
      shopifyHeaderError: 'shopify/headerError',
      wordpressHeaderError: 'wordpress/headerError',
      showExpirationRoadblock: 'roadblock/accountExpirationRoadblock'
    }),
    syncHeaderError () {
      return this.shopifyHeaderError || this.wordpressHeaderError
    },
    roadblockComponent () {
      // uncomment if we want to go back to an opt in/expired trial version of onboarding
      // const component = (this.showExpirationRoadblock && this.$route.name !== 'Upgrade')
      //   ? load(() => import('@/components/ExpirationRoadblockDialog.vue'))
      //   : this.$route.meta.roadblock
      // return this.roadblockUi && this.$route.name !== 'Upgrade' ? this.$route.meta.roadblock : null
      return this.roadblockUi ? this.$route.meta.roadblock : null
    },
    fullHeight () {
      if (this.$route && this.$route.meta && this.$route.meta.fullHeight) {
        if (this.$route.meta.fullHeight === true) {
          return true
        } else if (typeof this.$route.meta.fullHeight === 'function') {
          return this.$route.meta.fullHeight(this)
        }
      }
      return false
    },
    requireNewPassword () {
      return !!this.$store.getters.user?.force_password_reset
    },
    failedCharge () {
      return !this.$store.state.failedChargeOverride && !!this.$store.getters.account?.failed_charge_at
    },
    createAccount () {
      return this.$store.getters.account?.status === 'SIGNUP' && !this.$store.state.signupOverride
    }
  },
  watch: {
    appDarkMode () {
      this.$vuetify.theme.dark = this.appDarkMode
    }
  },
  async mounted () {
    this.$vuetify.theme.dark = this.appDarkMode
  }
}
</script>
