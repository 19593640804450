import Action from '../Action'
import colors from 'shared/colors'

class WooCreateCoupon extends Action {
  static get title () {
    return 'Woo Create Coupon'
  }

  static get class () {
    return 'WooCreateCoupon'
  }

  static get restrict () {
    return { addons: { $elemMatch: { type: 'WORDPRESS', 'config.woocommerce_enabled': true } } }
  }

  static get color () {
    return colors.maroonRed
  }

  static getDefaultProps () {
    return {
      type: 'PERCENT',
      // assign_to_customer: true, // Not possible with WOO, closest is adding the contact's email address here which won't always be reliable
      once_per_customer: true,
      // shipping_exclude_maximum: true, // Not possible with WOO
      duration_extend_midnight: true
    }
  }
}

export default WooCreateCoupon
