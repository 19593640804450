<template>
  <v-card
    class="cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title>Generate Discount Code</v-toolbar-title>
      <v-spacer />
      <v-btn
        icon
        :disabled="loading"
        @click="$emit('close')"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      v-if="discount && discount.discountCode"
      class="d-flex align-center justify-center"
    >
      <created-code-card
        :code="discount.discountCode.code"
      />
    </v-container>
    <v-container
      v-else
      fluid
      class="pa-0"
    >
      <shopify-discount-editor
        v-if="addon && addon.type === 'SHOPIFY'"
        ref="editor"
        :value="discountChanged"
        :contact-id="contactId"
        :disabled="loading"
        @change="$handleChange('discount', $event[0], $event[1])"
      />
      <woo-create-coupon-editor
        v-else-if="addon && addon.type === 'WORDPRESS'"
        ref="editor"
        :value="discountChanged"
        :contact-id="contactId"
        :disabled="loading"
        @change="$handleChange('discount', $event[0], $event[1])"
      />
    </v-container>
    <div
      class="d-flex footer pa-3"
      v-if="discount && discount.discountCode"
    >
      <v-spacer />
      <v-btn
        color="primary"
        outlined
        :disabled="loading"
        @click="$emit('close')"
      >Close</v-btn>
    </div>
    <div
      class="d-flex footer pa-3"
      v-else
    >
      <v-spacer />
      <v-btn
        color="primary"
        outlined
        :disabled="loading"
        @click="$emit('close')"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        @click="save"
        :loading="loading"
        :disabled="loading || invalid"
      >Generate Code</v-btn>
    </div>
  </v-card>
</template>

<script>
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import ShopifyDiscountEditor from 'ui/blocks/actions/ShopifyDiscount/Editor'
import WooCreateCouponEditor from 'ui/blocks/actions/WooCreateCoupon/Editor'
import CreatedCodeCard from './CreatedCodeCard.vue'
import ShopifyDiscount from 'shared/blocks/actions/ShopifyDiscount.js'
import WooCreateCoupon from 'shared/blocks/actions/WooCreateCoupon.js'
import { Addon } from 'ui/models'

export default {
  mixins: [
    createChangeTrackerMixin({ path: 'discount' })
  ],
  oWindow: {
    width: 900,
    height: 1100,
    persistent: true,
    overlay: true
  },
  components: {
    ShopifyDiscountEditor,
    WooCreateCouponEditor,
    CreatedCodeCard
  },
  props: {
    contactId: {
      type: String,
      required: true
    },
    onUpdate: {
      type: Function,
      default: () => () => {}
    }
  },
  data () {
    return {
      loading: false,
      event: null,
      invalid: false,
      discountData: null
    }
  },
  computed: {
    addon () {
      const shopifyAddon = Addon.query()
        .where(a => a.type === 'SHOPIFY' && a.active)
        .first()
      const wordpressAddon = Addon.query()
        .where(a => a.type === 'WORDPRESS')
        .first()

      return shopifyAddon || wordpressAddon
    },
    discount () {
      const defaults = {
        SHOPIFY: ShopifyDiscount.getDefaultProps(),
        WORDPRESS: WooCreateCoupon.getDefaultProps()
      }
      return this.discountData || defaults[this.addon?.type] || {}
    }
  },
  methods: {
    async save () {
      if (this.loading || (this.$refs.editor && this.$refs.editor.invalid)) {
        return
      }
      this.loading = true
      try {
        const result = await this.$http({
          method: 'POST',
          url: `/v2/contacts/${this.contactId}/discount_codes`,
          data: {
            addon_id: this.addon._id,
            props: this.discountChanged
          }
        })
        if (result?.data) {
          this.discountData = result.data.discount
          this.event = result.data.event
        }
        this.$clearChanges()
        this.onUpdate()
      } finally {
        this.loading = false
      }
    }
  },
  mounted () {
    this.invalid = this.$refs.editor.invalid
    this.$watch(
      '$refs.editor.invalid',
      (value) => {
        this.invalid = value
      }
    )
  }
}
</script>
