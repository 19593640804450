<template>
  <div>
    <div
      class="pa-3"
      style="position: relative; overflow-x: auto;"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th>
              <v-progress-circular
                v-if="loading"
                size="20"
                color="primary"
                indeterminate
              />
            </th>
            <th>PAYMENT SOURCE</th>
            <th><span class="ml-3">ACCOUNT DEFAULT</span></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="record in records"
            :key="record._id"
          >
            <td>
              <div style="display: inline-block; white-space: nowrap;">
                <!-- <v-btn
                  icon
                  small
                  @click="edit(record)"
                >
                  <v-icon small>fa fa-edit</v-icon>
                </v-btn> -->
                <v-btn
                  v-if="!record.default || !record.verified"
                  icon
                  small
                  @click="destroy(record)"
                  :disabled="loading"
                >
                  <v-icon small>fa fa-trash</v-icon>
                </v-btn>
                <v-tooltip
                  v-if="record.type === 'SHOPIFY'"
                  top
                  max-width="350"
                >
                  <template v-slot:activator="{ on }">
                    <span
                      v-on="on"
                    >
                      <v-btn
                        icon
                        small
                        @click="refresh(record)"
                        :disabled="loading"
                      >
                        <v-icon small>fa fa-redo</v-icon>
                      </v-btn>
                    </span>
                  </template>
                  <div>Re-sync this payment source with Shopify</div>
                </v-tooltip>

                <v-tooltip
                  v-if="record.errorMessage"
                  top
                  max-width="350"
                >
                  <template v-slot:activator="{ on }">
                    <v-icon
                      class="ml-2"
                      v-on="on"
                      color="error"
                    >fas fa-exclamation-circle</v-icon>
                  </template>
                  <div>{{ record.errorMessage }}</div>
                </v-tooltip>
              </div>
            </td>
            <td>
              <div class="py-2">
                <div
                  style="display: inline-block;"
                >
                  <payment-source-ref
                    :record-id="record._id"
                  />
                </div>
                <v-btn
                  style="vertical-align: top;margin-top: 2px; margin-left: 25px"
                  v-if="record.type === 'ACH' && !record.verified"
                  color="warning"
                  x-small
                  :disabled="loading"
                  @click="verifyAch(record)"
                >Verify ACH</v-btn>
                <v-btn
                  v-if="record.shopifyConfirmUrl && record.upgradeToConfirm"
                  style="vertical-align: top;margin-top: 2px; margin-left: 25px"
                  color="warning"
                  x-small
                  :disabled="loading"
                  :to="{ name: 'Upgrade' }"
                >Upgrade To Confirm</v-btn>
                <v-btn
                  style="vertical-align: top;margin-top: 2px; margin-left: 25px"
                  v-else-if="record.shopifyConfirmUrl"
                  color="warning"
                  x-small
                  :disabled="loading"
                  :href="record.shopifyConfirmUrl"
                >Confirm On Shopify</v-btn>
              </div>
            </td>
            <td>
              <span
                v-if="record.default"
                class="success--text pa-3"
              >DEFAULT</span>
              <v-btn
                v-else-if="record.verified"
                small
                color="primary"
                text
                @click="makeDefault(record)"
                :disabled="loading"
              >MAKE DEFAULT</v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-btn
        v-if="!disableAdd && (admin || !$restrict({ 'addons.type': 'SHOPIFY' }))"
        fab
        color="primary"
        small
        style="position: absolute; right: 15px; bottom: 15px;"
        @click="create"
        :disabled="loading"
      >
        <v-icon small>fa fa-plus</v-icon>
      </v-btn>
    </div>
    <v-footer
      class="paginator-bar d-flex"
      style="height: 50px"
    >
      <v-spacer />
      <paginator
        v-bind="paymentSourcesPagination"
        v-on="paymentSourcesPaginator"
        :loading="loading"
        :dense="dense"
      />
    </v-footer>
  </div>
</template>

<script>
import { PaymentSource } from 'ui/models'
import { Paginator } from 'ui/components/DataTable'
import createPaginationMixin from 'ui/mixins/createPaginationMixin'
import PaymentSourceEditorDialog from './PaymentSourceEditor/PaymentSourceEditorDialog'
import PaymentSourceVerifyDialog from './PaymentSourceEditor/PaymentSourceVerifyDialog'
import PaymentSourceRef from 'ui/references/PaymentSource'

export default {
  mixins: [
    createPaginationMixin(PaymentSource, {
      requestOptionsPath: 'requestOptions',
      defaultSort: [['_id', -1]]
    })
  ],
  components: {
    Paginator,
    PaymentSourceRef
  },
  props: {
    admin: { // Whether this component is being loaded within the admin section
      type: Boolean,
      default: false
    },
    disableAdd: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      internalLoading: false
    }
  },
  computed: {
    loading () {
      return this.internalLoading || this.paymentSourcesPagination.loading
    },
    requestOptions () {
      return {}
    },
    records () {
      return this.paymentSourcesRecords.map(record => {
        record = {
          ...record
        }
        if (record.failed_charge_at) {
          record.errorMessage = `This payment source had a failed charge at ${this.$timestamp(record.failed_charge_at)}.`
        }
        if (record.type === 'SHOPIFY') {
          if (!record.verified && record.data?.recurring_application_charge?.confirmation_url && (record?.data?.recurring_application_charge?.status || '').toUpperCase() === 'PENDING') {
            record.shopifyConfirmUrl = record.data?.recurring_application_charge?.confirmation_url
            // If the payment source was added as the result of upgrading, the return url will trigger an automatic
            // upgrade.  If it wasn't confirmed (possibly because they bailed before approving the payment source)
            // then we don't want to redirect them to this URL since it will automatically upgrade their account
            // after.  So, we want to redirect them to the upgrade page first.
            if ((record.data?.recurring_application_charge?.return_url || '').includes('upgrade_after=true')) {
              record.upgradeToConfirm = true
            }
          }
          if (!['PENDING', 'ACTIVE'].includes((record?.data?.recurring_application_charge?.status || '').toUpperCase())) {
            record.errorMessage = `This payment source has a status of ${(record?.data?.recurring_application_charge?.status || '').toUpperCase()} and cannot be used.`
          }
        }
        return record
      })
    }
  },
  methods: {
    async loadData () {
      this.internalLoading = true
      try {
        await this.paymentSourcesPaginationLoad()
      } finally {
        this.internalLoading = false
      }
    },
    async create () {
      const result = await this.$openWindow({
        component: PaymentSourceEditorDialog
      })
      if (result?.record) {
        this.loadData()
      }
    },
    async edit (record) {
      const result = await this.$openWindow({
        component: PaymentSourceEditorDialog,
        props: {
          recordId: record._id
        }
      })
      if (result?.record) {
        this.loadData()
      }
    },
    async refresh (record) {
      this.internalLoading = true
      try {
        await PaymentSource.save(record)
      } finally {
        this.internalLoading = false
      }
    },
    async destroy (record) {
      if ((await this.$confirm('Are you sure you want to delete this payment source?')) !== 'Yes') {
        return
      }
      this.internalLoading = true
      try {
        await PaymentSource.destroy(record)
      } finally {
        this.internalLoading = false
      }
    },
    async makeDefault (record) {
      this.internalLoading = true
      try {
        await PaymentSource.save({
          ...record,
          default: true
        })
        await this.loadData()
      } finally {
        this.internalLoading = false
      }
    },
    async verifyAch (record) {
      const result = await this.$openWindow({
        component: PaymentSourceVerifyDialog,
        props: {
          recordId: record._id
        }
      })
      if (result?.record) {
        this.loadData()
      }
    }
  },
  created () {
    this.loadData()
  }
}
</script>
