import Model from './Model'

class Document extends Model {
  static entity = 'documents'

  static fields () {
    return {
      _id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      type: this.attr(undefined),
      tags: this.attr(undefined),
      smartlink_id: this.attr(undefined),
      original_file: this.attr(undefined),
      pages: this.attr(undefined),
      content: this.attr(undefined),
      settings: this.attr(undefined),
      ops: this.attr(undefined),
      permissions: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined)
    }
  }
}

export default Document
