<template>
  <v-card
    class="cg-editor"
  >
    <v-toolbar
      dark
      flat
      class="drag-handle"
    >
      <v-toolbar-title
        v-if="!loading && !(record && record._id) && !recordChanged.type"
      >Create Offer</v-toolbar-title>
      <v-toolbar-title
        v-else-if="!loading"
        class="flex-grow-1"
      >
        <v-text-field
          style="max-width: 300px"
          outlined
          flat
          dense
          maxlength="40"
          placeholder="Give this SmartOffer a name"
          :value="(recordChanged && recordChanged.name) || ''"
          @input="$handleChange('record', 'name', ($event || '').substr(0, 40))"
          hide-details
          @keyup.enter="save()"
          :disabled="loading"
        />
      </v-toolbar-title>

      <v-spacer />

      <v-switch
        v-if="(record && record._id) || !!recordChanged.type"
        class="mr-3 ml-2"
        :label="'Enable' + (recordChanged && recordChanged.active ? 'd' : '')"
        color="success"
        hide-details
        :loading="loading"
        :input-value="recordChanged && recordChanged.active"
        readonly
        @click="toggleActive"
      />

      <v-btn
        v-if="(recordChanges.length || !(record && record._id)) && recordChanged.type"
        icon
        :disabled="loading"
        @click="save"
      >
        <v-icon>fa fa-save</v-icon>
      </v-btn>

      <permissions-btn
        v-if="$store.getters.isMultiAccount && record && record._id && record.account_id === $store.getters.account._id"
        @input="$handleChange('record', 'permissions', $event)"
        :record="recordChanged"
        :small="false"
      />

      <v-btn
        icon
        :disabled="loading"
        @click="requestClose"
      >
        <v-icon>fa fa-times</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container
      v-if="initialLoading"
      fluid
      class="d-flex align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        indeterminate
      />
    </v-container>
    <v-container
      v-else-if="!recordChanged.type || editType"
      fluid
    >
      <offer-name-and-type
        :record="recordChanged"
        @change="updateOfferNameAndType"
        :disabled="loading"
      />
    </v-container>
    <v-container
      v-else
      fluid
      class="pa-0"
    >
      <offer-stepper
        v-model="step"
        v-bind="{ validators, maxStepReached }"
        :record="recordChanged"
        class="flex-grow-1"
        @save-enable="saveAndEnable"
        :disabled="loading"
      >
        <offer-details
          slot="details"
          :record="recordChanged"
          @change="$handleChange('record', $event[0], $event[1])"
          :disabled="loading"
          @edit-type="editType = true"
        />
        <offer-design
          slot="design"
          :record="recordChanged"
          @change="$handleChange('record', $event[0], $event[1])"
          :disabled="loading"
        />
        <offer-advanced
          slot="advanced"
          :record="recordChanged"
          @change="$handleChange('record', $event[0], $event[1])"
          :disabled="loading"
        />
        <offer-promote
          slot="promote"
          :record="recordChanged"
          @change="$handleChange('record', $event[0], $event[1])"
          :disabled="loading"
        />
      </offer-stepper>

    </v-container>
    <!-- <div class="footer pa-2">
      <v-btn
        color="primary"
        outlined
        @click="requestClose"
        :disabled="loading"
      >Cancel</v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        @click="save"
        :disabled="loading || !recordChanged.name || !recordChanged.type"
      >Save</v-btn>
    </div> -->
  </v-card>
</template>

<style lang="scss">
.offer-stepper {
  background-color: transparent !important;
}
</style>

<script>
import { Offer } from 'ui/models'
import createPreventChangeLossMixin from 'ui/mixins/createPreventChangeLossMixin'
import createChangeTrackerMixin from 'ui/mixins/createChangeTrackerMixin'
import OfferNameAndType from './OfferNameAndType.vue'
import OfferStepper from './OfferStepper'
import OfferDetails from './OfferDetails'
import OfferDesign from './OfferDesign'
import OfferAdvanced from './OfferAdvanced'
import OfferPromote from './OfferPromote'
import { PermissionsBtn } from 'ui/components/Permissions'
import rgbaToArgb from 'shared/util/colors/rgbaToArgb'

export default {
  oWindow: {
    width: '100%',
    height: '100%'
  },
  mixins: [
    createPreventChangeLossMixin({
      checkIsRouteLeaving: (to, from) => {
        return to.path !== from.path
      },
      beforeRouteLeave: async function (to, from, next) {
        if (this.loading) {
          return next(false)
        }
        next()
      },
      beforeRouteUpdate: async function (to, from, next) {
        if (this.loading) {
          return next(false)
        }
        await next()
        this.getStepFromRoute()
      }
    }),
    createChangeTrackerMixin({ path: 'record' })
  ],
  components: {
    PermissionsBtn,
    OfferNameAndType,
    OfferStepper,
    OfferDetails,
    OfferDesign,
    OfferAdvanced,
    OfferPromote
  },
  props: {
    recordId: {
      type: [Number, String],
      default: null
    },
    initialProps: {
      type: Object,
      default: () => ({})
    },
    syncRouter: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      initialLoading: false,
      loading: false,
      editType: false,
      step: 1,
      maxStepReached: 1,
      newRecordId: null
    }
  },
  computed: {
    record () {
      if ((!this.recordId || this.recordId === 'new') && !this.newRecordId) {
        const type = this.allChanges.find(r => r.recordKey === 'record' && r.path === 'type')?.value
        return {
          channel: 'KIOSK',
          color_background: rgbaToArgb(this.$store.getters.account?.settings?.style?.content_background_color),
          color_font: rgbaToArgb(this.$store.getters.account?.settings?.style?.color),
          color_button: rgbaToArgb(this.$store.getters.account?.settings?.style?.button_background_color),
          color_button_text: rgbaToArgb(this.$store.getters.account?.settings?.style?.button_color),
          ...(type === 'PROMOTIONAL' ? { activation_props: { duration: { days: 3, hours: 12 } } } : {}),
          ...this.initialProps
        }
      }
      return Offer.find(this.newRecordId || this.recordId)
    },
    validators () {
      return []
    },
    portalVersionTag () {
      return 'P' + (process?.env?.VUE_APP_CLIENT_VERSION || global?.VUE_APP_CLIENT_VERSION || '').charAt(0)
    }
  },
  watch: {
    step () {
      // this.maxStepReached = Math.max(this.step * 1, this.maxStepReached)
      if (this.syncRouter) {
        if (this.$router.currentRoute.query.step + '' !== this.step + '') {
          this.$router.push({
            query: {
              ...this.$router.currentRoute.query,
              step: this.step
            }
          })
        }
      }
    }
  },
  methods: {
    getStepFromRoute () {
      if (
        this.syncRouter &&
        Object.keys(this.$router.currentRoute.query).includes('step') &&
        this.$router.currentRoute.query.step + '' !== this.step + ''
      ) {
        this.step = this.$router.currentRoute.query.step
      }
    },
    updateOfferNameAndType ($event) {
      this.$handleChange('record', $event[0], $event[1])
      // If the type has been selected then no longer force the name/type view to show
      if ($event[0] === 'type') {
        this.editType = false
      }
    },
    async toggleActive (e) {
      if (this.loading) {
        return
      }
      this.$handleChange('record', 'active', !this.recordChanged.active)
      try {
        const promise = this.save()
        await this.$manageLoading('loading', promise)
      } catch (e) {
        this.$handleChange('record', 'active', !this.recordChanged.active)
        throw e
      }
    },
    async save ({ nextStep } = {}) {
      if (this.loading) {
        return
      }
      const updateTag = `update-${this.portalVersionTag}`
      if (!(this.recordChanged?.tags || []).includes(updateTag)) {
        this.$handleChange('record', 'tags', [
          ...(this.recordChanged?.tags || []),
          updateTag
        ])
      }
      try {
        this.loading = true
        const result = await Offer.save(this.recordChanged)
        this.$clearChanges()
        this.loading = false
        if (this.syncRouter && !this.newRecordId && (!this.recordId || this.recordId === 'new') && result.entities?.offers?.[0]?._id) {
          this.loading = false
          this.$router.replace({
            name: 'OfferEditor',
            params: {
              recordId: result.entities.offers[0]._id
            },
            query: {
              ...(this.$route.query || {}),
              duplicate: undefined,
              ...(nextStep ? { step: nextStep } : {})
            }
          })
        } else if ((!this.recordId || this.recordId === 'new') && !this.newRecordId && result.entities?.offers?.[0]?._id) {
          this.newRecordId = result.entities?.offers?.[0]?._id
        }
      } finally {
        this.loading = false
      }
    },
    async saveAndEnable () {
      this.$handleChange('record', 'active', true)
      try {
        await this.save({ nextStep: 4 })
      } catch (e) {
        this.$handleChange('record', 'active', this.record.active)
        throw e
      }
    }
  },
  async created () {
    if (this.$router.currentRoute.query.duplicate && this.recordId === 'new') {
      await Offer.fetchOne(this.$router.currentRoute.query.duplicate)
      const offerToDuplicate = Offer.find(this.$router.currentRoute.query.duplicate)
      if (!offerToDuplicate) {
        this.$alert('Unable to find offer to duplicate.')
      }
      Object.keys(offerToDuplicate)
        .filter(key => !['_id', 'id', 'smartlink_id'].includes(key))
        .forEach(key => {
          let value = offerToDuplicate[key]
          if (key === 'name') {
            value = (value || '') + ' (duplicate)'
          }
          this.$handleChange('record', key, value)
        })
    }
    if (this.syncRouter) {
      if (!this.$router.currentRoute.query.step) {
        this.$router.replace({
          query: {
            ...this.$router.currentRoute.query,
            step: 1
          }
        })
      }
    }
    this.getStepFromRoute()
    if (this.recordId) {
      if (this.recordId === 'new') {
        this.$handleChange('record', 'offersV2', true)
        this.$handleChange('record', 'require_verification', ['phone'])
        this.$handleChange('record', 'tags', [`create-${this.portalVersionTag}`])
        return
      }
      this.loading = true
      this.initialLoading = true
      try {
        await Offer.fetchOne(this.recordId, { force: true })
      } catch (e) {
        this.$emit('close') // If we can't load the record, don't show the editor.  Error toast will be displayed.
        throw e
      } finally {
        this.loading = false
        this.initialLoading = false
      }
    }
  }
}
</script>
