<template>
  <v-alert
    v-if="showHeader"
    class="sync-header mb-0"
    :color="color"
    dark
  >
    <p
      v-if="loading"
      class="mb-0"
    >
      <v-progress-circular
        v-if="loading"
        indeterminate
        color="error"
      />
      &nbsp;&nbsp;
      <strong>Loading sync data...</strong>
    </p>
    <div
      v-else-if="syncing"
    >
      <span>Account Sync in Progress ({{Math.floor(progress)}}%).  Click <a :href="settingsUrl" style="color: #fff;">here</a> to complete your account setup.</span>
    </div>
    <span v-else-if="headerError === 'needsSync'">You have not synced your {{ addonName }} data! Sync your data <a style="color: #fff; text-decoration: underline;" :href="settingsUrl">here</a> to start patching your leaky bucket!</span>
    <span v-else-if="headerError === 'lastSyncFailed'">It looks like something went wrong with your last sync. Use the Order Data options <a style="color: #fff" :href="settingsUrl">here</a> to try again.</span>
    <span
      v-else-if="headerError === 'completed'"
    >
      Sync Complete! The data is in. Head over to your <a style="color: #fff" :href="dashboardUrl">Retention Dashboard</a> to see which buckets are leaking the most.
    </span>
    <v-btn
      v-if="headerError === 'completed'"
      icon
      right
      absolute
      class="mt-n2"
      @click="showHeader = false"
      :disabled="loading"
    >
      <v-icon>fa fa-times</v-icon>
    </v-btn>
  </v-alert>
</template>
<style>
.sync-header a {
  color: #FFFFFF;
}
</style>
<script>
import { mapGetters, mapState } from 'vuex'
import { Addon } from 'ui/models'

export default {
  data () {
    return {
      showHeader: true
    }
  },
  computed: {
    ...mapGetters({
      shopifyHeaderError: 'shopify/headerError',
      shopifyProgress: 'shopify/progress',
      shopifySyncing: 'shopify/syncing'
    }),
    ...mapState('shopify', {
      shopifyLoading: 'syncLoading',
      shopifyDismissable: 'syncHeaderCanBeDismissed',
      shopifySyncData: 'syncData'
    }),
    ...mapGetters({
      wordpressHeaderError: 'wordpress/headerError',
      wordpressProgress: 'wordpress/progress',
      wordpressSyncing: 'wordpress/syncing'
    }),
    ...mapState('wordpress', {
      wordpressLoading: 'syncLoading',
      wordpressDismissable: 'syncHeaderCanBeDismissed',
      wordpressSyncData: 'syncData'
    }),
    headerError () {
      return this[(this.addonType || '').toLowerCase() + 'HeaderError']
    },
    progress () {
      return this[(this.addonType || '').toLowerCase() + 'Progress']
    },
    syncing () {
      return this[(this.addonType || '').toLowerCase() + 'Syncing']
    },
    loading () {
      return this[(this.addonType || '').toLowerCase() + 'Loading']
    },
    dismissable () {
      return this[(this.addonType || '').toLowerCase() + 'Dismissable']
    },
    syncData () {
      return this[(this.addonType || '').toLowerCase() + 'SyncData']
    },
    color () {
      let color = 'warning'
      if (this.headerError === 'completed') {
        color = 'success'
      }
      if (this.headerError === 'syncInProgress') {
        color = 'primary'
      }
      return color
    },
    settingsUrl () {
      return `/${this.$store.getters.account?._id}/settings/${(this.addonType || '').toLowerCase()}`
    },
    dashboardUrl () {
      return `/${this.$store.getters.account?._id}/dashboard/retention`
    },
    addonType () {
      if (this.shopifyHeaderError) {
        return 'SHOPIFY'
      } else if (this.wordpressHeaderError) {
        return 'WORDPRESS'
      }
      return null
    },
    addon () {
      return Addon.query()
        .where(a => a.type === this.addonType)
        .first()
    },
    addonName () {
      return {
        WORDPRESS: 'WooCommerce',
        SHOPIFY: 'Shopify'
      }[this.addonType]
    }
  }
}
</script>
