<template>
  <v-sheet>
    <v-list
      dense
      class="left-navigation-settings-dropdown"
    >
      <v-list-item
        v-if="account && $restrict({ $or: [{ 'user.level': { $gte: 5 } }, { 'accountUser.level': 'FULL'}, {'addons.type': 'SETTINGS' }] })"
        :to="{ name: 'Settings' }"
      >
        <v-list-item-icon>
          <v-icon>fa-cog</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Account Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="user && !account && accounts.length === 1"
        :to="{ name: 'Dashboard', params: { accountId: accounts[0]._id } }"
      >
        <v-list-item-icon>
          <v-icon>far fa-building</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ account.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-else-if="accounts.length > 1"
        style="position: relative; cursor: pointer;"
      >
        <v-list-item-icon
          @click="switchAccounts"
        >
          <v-icon>far fa-building</v-icon>
        </v-list-item-icon>

        <v-list-item-content
          class="d-flex align-center pr-2"
          @click="switchAccounts"
        >
          <v-list-item-title
            class="flex-grow-1"
          >{{ account ? 'Switch Account' : 'Select Account' }}</v-list-item-title>
        </v-list-item-content>
        <router-link
          style="position: absolute; right: 10px; top: -2px;"
          :to="{ name: 'SelectAccount' }"
          target="_blank"
          @click.stop.prevent
        >
          <v-icon x-small>fa-solid fa-up-right-from-square</v-icon>
        </router-link>
      </v-list-item>
      <v-list-item
        @click="$store.commit('toggleDarkMode')"
      >
        <v-list-item-icon>
          <v-icon>far fa-lightbulb</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Switch to {{appDarkMode ? 'Light' : 'Dark'}} Mode</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-if="user"
        :to="{ name: $store.getters.accountId ? 'ProfileAccount' : 'Profile' }"
      >
        <v-list-item-icon>
          <v-icon>fa-user-cog</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <template
        v-if="account && user && user.level >= 3"
      >
        <v-list-item
          @click="openAccountAdmin"
        >
          <v-list-item-icon>
            <v-icon>fas fa-beer</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Admin</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider />
      </template>
      <v-list-item
        v-if="user"
        @click="$store.dispatch('logout')"
      >
        <v-list-item-icon>
          <v-icon>fa-sign-out-alt</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Log Out</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-sheet>
</template>

<style lang="scss" scoped>
.left-navigation-settings-dropdown {
  .v-list-item__icon {
    margin-right: 10px !important;
    .v-icon {
      font-size: 1rem;
    }
  }
  .v-divider {
    border-color: rgba(128, 128, 128, 0.2) !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
</style>

<script>
import { Account } from 'ui/models'
import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState(['appDarkMode']),
    ...mapGetters(['user', 'account']),
    accounts () {
      // TODO Filter this by AccountUser as pivot
      return Account.query()
        .get()
    }
  },
  methods: {
    async switchAccounts () {
      await this.$router.push({ name: 'SelectAccount' })
      await this.$store.dispatch('empty')
    },
    async openAccountAdmin () {
      const { default: component } = await import('admin/src/components/AccountEditor')
      this.$openWindow({
        component,
        props: {
          recordId: this.account._id
        }
      })
    }
  }
}
</script>
