import { Addon } from 'ui/models'
import { get } from 'lodash'
import moment from 'moment-timezone'
import Vue from 'vue'
let reCheckTimeout

export default {
  namespaced: true,
  state: {
    syncData: null,
    syncLoading: false,
    syncHeaderCanBeDismissed: false
  },
  getters: {
    addon () {
      return Addon.query()
        .where(a => a.type === 'WORDPRESS')
        .first()
    },
    headerError (state, getters) {
      let error = null
      const record = getters.addon
      // logic to determine what state syncs is in
      if (record) {
        if (state.syncData?.syncing === true) {
          error = 'syncInProgress'
        } else if (!record.config?.woocommerce_enabled) {
          return null
        } else if (!record.config?.last_sync_completed_at) {
          error = 'needsSync'
        } else if (record.config?.last_sync_started_at && moment(record.config?.last_sync_completed_at).isBefore(moment(record.config?.last_sync_started_at))) {
          error = 'lastSyncFailed'
        } else if (state.syncHeaderCanBeDismissed) {
          error = 'completed'
        }
      }

      return error
    },
    syncing (state) {
      return state.syncData?.syncing
    },
    progress (state) {
      return state.syncData?.done
        ? 100
        : (state.syncData?.iteration / state.syncData?.iterations_total || 0) * 100
    }
  },
  mutations: {
    setSyncData (state, payload) {
      state.syncData = payload
    },
    setSyncLoading (state, payload) {
      state.syncLoading = payload
    },
    setSyncHeaderCanBeDismissed (state, payload) {
      state.syncHeaderCanBeDismissed = payload
    }
  },
  actions: {
    async getSyncStatus ({ state, getters, commit, dispatch }, payload) {
      if (state.syncLoading) {
        return
      }
      const method = payload?.method || 'GET'
      commit('setSyncLoading', true)
      try {
        const response = await Vue.$http({
          method,
          url: `/v2/addons/${getters.addon._id}/call/sync_data`
        })
        const data = get(response, 'data.data') || {}
        commit('setSyncData', data)
        clearTimeout(reCheckTimeout)
        if (data.syncing) {
          reCheckTimeout = setTimeout(() => {
            dispatch('getSyncStatus')
          }, 30 * 1000)
          commit('setSyncHeaderCanBeDismissed', true)
        }
        Addon.fetchOne(getters.addon._id, { force: true })
        commit('setSyncLoading', false)
      } catch (e) {
        commit('setSyncLoading', false)
        throw e
      }
    },
    async syncData ({ dispatch }) {
      return dispatch('getSyncStatus', { method: 'POST' })
    }
  }
}
