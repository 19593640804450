import Model from './Model'
import { defaultValues } from 'shared/schemas/entities/account'

class Account extends Model {
  static entity = 'accounts'

  // Eventually I'd like to evict unused of these but one prereq is to store the accounts that the user has access to somewhere more reliable
  // static evictUnused = true

  static fields () {
    return {
      _id: this.attr(undefined),
      name: this.attr(''),
      display_name: this.attr(undefined),
      parents: this.attr(undefined),
      parent: this.attr(undefined),
      children: this.attr(undefined),
      inter_accounts: this.attr(undefined),
      identifier: this.attr(undefined),
      settings: this.attr(undefined),
      billing: this.attr(undefined),
      rep_id: this.attr(undefined),
      status: this.attr(undefined),
      created_at: this.attr(undefined),
      updated_at: this.attr(undefined),
      __citygro: this.attr(undefined),
      failed_charge_at: this.attr(undefined),
      activated_at: this.attr(undefined),
      lost_at: this.attr(undefined),
      _sh: this.attr(undefined)
    }
  }

  static get defaultValues () {
    return defaultValues
  }
}

export default Account
