<template>
  <v-card
    class="pa-3"
  >
    <div class="mb-2">
      <strong>Fix the following errors:</strong>
    </div>
    <div
      class="d-flex mt-2"
      v-for="error in value"
      :key="error.message"
    >
      <div>
        <v-icon
          color="error"
          small
        >fas fa-exclamation-triangle</v-icon>
      </div>
      <div
        class="flex-grow-1 error--text pl-4"
      >{{ error.message }}</div>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    }
  }
}
</script>
