<template>
  <div>
    <v-card>
      <v-card-title class="offer-card-titles pb-0 pt-2">Signed Waivers</v-card-title>
      <div
        v-if="loadingDocuments && !existingDocuments.length"
        class="d-flex align-center justify-center py-4"
        style="height: 100%"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </div>
      <v-simple-table
        v-else-if="existingDocuments.length"
        dense
      >
        <thead>
        <tr>
          <th>
            <v-btn
              @click="loadData({ refresh: true })"
              :disabled="loadingDocuments"
              icon
              small
            >
              <v-icon small>fa-redo</v-icon>
            </v-btn>
          </th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Name</th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Create Time</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="existingDocument in existingDocuments"
          :key="existingDocument._id"
        >
          <td class="d-flex align-center justify-start">
            <v-btn
              @click="openDocumentViewer(existingDocument._id)"
              :disabled="deletingDocument || loadingDocuments || sendingDocuments"
              icon
              small
            >
              <v-icon small>fa-file-pdf</v-icon>
            </v-btn>
            <v-btn
              v-if="contact && contact.email"
              @click="sendDocument(existingDocument)"
              :disabled="deletingDocument || loadingDocuments || sendingDocuments"
              icon
              small
            >
              <v-icon small>fa-envelope</v-icon>
            </v-btn>
            <v-btn
              @click="deleteDocument(existingDocument)"
              :disabled="deletingDocument || loadingDocuments || sendingDocuments"
              icon
              small
            >
              <v-icon small>fa-trash</v-icon>
            </v-btn>
          </td>
          <td>{{ existingDocument.waiver_name || '' }}</td>
          <td>
            <ui-timestamp :value="existingDocument.created_at"/>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-text v-else>
        <v-btn
          @click="loadData({ refresh: true })"
          :disabled="loadingDocuments"
          icon
          small
        >
          <v-icon small>fa-redo</v-icon>
        </v-btn>
        <span class="ml-2">No waivers have been signed.</span>
      </v-card-text>
    </v-card>
    <v-card class="mt-4">
      <v-card-title class="offer-card-titles pb-0 pt-2">
        Deleted Waivers
        <v-tooltip
          bottom
          :max-width="300"
        >
          <template v-slot:activator="{ on }">
            <v-icon
              class="ml-1 mb-2"
              v-on="on"
              x-small
            >fa-info-circle</v-icon>
          </template>
          <div>The data from deleted waivers will not be deducted from the stats used within your dashboards. Deleted waivers cannot be recovered.</div>
        </v-tooltip>
      </v-card-title>
      <div
        v-if="loadingDeletions && !deletedDocuments.length"
        class="d-flex align-center justify-center py-4"
        style="height: 100%"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </div>
      <v-simple-table
        v-else-if="deletedDocuments.length"
        dense
      >
        <thead>
        <tr>
          <th>
            <v-btn
              @click="loadData"
              :disabled="loadingDeletions"
              icon
              small
            >
              <v-icon small>fa-redo</v-icon>
            </v-btn></th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Name</th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Create Time</th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Delete Time</th>
          <th
            class="text-uppercase"
            :style="`color: ${$vuetify.theme.currentTheme.primary}`"
          >Deleted By</th>
        </tr>
        </thead>
        <tbody>
        <tr
          style="color: gray;"
          v-for="deletedDocument in deletedDocuments"
          :key="deletedDocument._id"
        >
          <td></td>
          <td>{{ deletedDocument.waiver_name || '' }}</td>
          <td>
            <ui-timestamp :value="deletedDocument.created_at"/>
          </td>
          <td>
            <ui-timestamp :value="deletedDocument.deleted_at"/>
          </td>
          <td>
            <user-ref :record-id="deletedDocument.deleted_by"/>
          </td>
        </tr>
        </tbody>
      </v-simple-table>
      <v-card-text v-else>No waivers have been deleted.</v-card-text>
    </v-card>
  </div>
</template>

<script>
import { get } from 'lodash'
import DocumentViewer from 'ui/viewers/DocumentViewer'
import UserRef from 'ui/references/User'
import ContactEditor from '../'
import { Contact } from 'ui/models'
import { mapGetters } from 'vuex'

export default {
  components: {
    UserRef
  },
  mixins: [
    Contact.mixin({ references: ['contact'] })
  ],
  props: {
    contactId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      documentEvents: [],
      deletingDocument: false,
      loadingDocuments: false,
      loadingDeletions: false,
      sendingDocuments: false
    }
  },
  computed: {
    ...mapGetters(['timezone']),
    contact () {
      return Contact.getFlattened(this.contactId)
    },
    deletedDocuments () {
      return this.documentEvents.filter(doc => !!doc.deleted_at)
    },
    existingDocuments () {
      return this.documentEvents.filter(doc => !doc.deleted_at)
    }
  },
  watch: {
    loading () {
      this.$emit('updateCount', this.loading ? 'loading' : this.existingDocuments.length)
    },
    documentEvents () {
      this.$emit('updateCount', this.loading ? 'loading' : this.existingDocuments.length)
    }
  },
  async mounted () {
    await this.loadData()
  },
  methods: {
    async loadData ({ refresh = null } = {}) {
      if (this.loadingDocuments) {
        return
      }
      this.loadingDocuments = true
      try {
        this.documentEvents = get(await this.$http({
          method: 'GET',
          url: `/v2/contacts/${this.contactId}/files`,
          params: {
            refresh: refresh ? 'true' : undefined
          }
        }), 'data.data')
        this.loadingDocuments = false
      } catch (e) {
        this.loadingDocuments = false
        throw e
      }
    },
    async openDocumentViewer (fileId) {
      this.$openWindow({
        component: DocumentViewer,
        props: {
          contactId: this.contactId,
          fileId,
          contactEditor: ContactEditor
        }
      })
    },
    async sendDocument (documentEvent) {
      if (this.sendingDocuments || (await this.$confirm(`Do you want to send a copy of this waiver to: ${this.contact.email} ?`)) !== 'Yes') {
        return
      }
      this.sendingDocuments = true
      try {
        await this.$http({
          method: 'POST',
          url: `/v2/contacts/${this.contactId}/files/${documentEvent._id}/send`
        })
        this.$alert(`The waiver was successfully sent to ${this.contact.email}.`)
        this.sendingDocuments = false
      } catch (e) {
        this.sendingDocuments = false
        throw e
      }
    },
    async deleteDocument (documentEvent) {
      if (this.deletingDocument) {
        return
      }
      const confirmText = 'DELETE'
      const confirmationText = `Are you sure you want to delete the waiver '${documentEvent.waiver_name || ''}'?  This cannot be undone.  Please type "${confirmText}" to continue.`
      let result = await this.$prompt(confirmationText, {
        title: 'Confirm Delete',
        overlay: true,
        inputProps: {
          placeholder: confirmText
        },
        buttons: [{
          name: 'DELETE',
          color: 'warning'
        }]
      })
      // Do not run for empty result (user cancelled).
      while (result && result !== confirmText) {
        await this.$alert('The text was not entered correctly. Please try again.', { title: 'OK' })
        result = await this.$prompt(confirmationText, {
          title: 'Confirm Delete',
          overlay: true,
          inputProps: {
            placeholder: confirmText,
            value: result
          },
          buttons: [{
            name: 'DELETE',
            color: 'warning'
          }]
        })
      }
      if (result === confirmText) {
        this.deletingDocument = true
        try {
          await this.$http({
            method: 'DELETE',
            url: `/v2/events/${documentEvent._id}`
          })
          this.deletingDocument = false
          await this.loadData()
        } catch (e) {
          this.deletingDocument = false
          throw e
        }
      }
    }
  }
}
</script>
