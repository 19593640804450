<template>
  <v-card
    class="pa-3"
  >
    <div class="mb-2">
      <strong>Minimum Requirements</strong>
    </div>
    <v-radio-group
      class="mt-0"
      :value="value && value.minimum_requirement || 'NONE'"
      @change="$emit('change', ['minimum_requirement', $event === 'NONE' ? null : $event]),$emit('change', ['minimum_amount', null])"
      dense
      hide-details
      v-bind="{ disabled }"
    >
      <v-radio
        value="NONE"
        label="None"
      />
      <v-radio
        value="AMOUNT"
        label="Minimum Purchase Amount ($)"
      />
    </v-radio-group>
    <v-text-field
      class="mt-3"
      v-if="value && value.minimum_requirement === 'AMOUNT'"
      style="max-width: 250px"
      label="Minimum Purchase Amount"
      type="tel"
      persistent-placeholder
      placeholder="10.25"
      prefix="$"
      suffix="Dollars"
      outlined
      dense
      :value="value && value.minimum_amount"
      @input="$emit('change', ['minimum_amount', $event])"
      v-bind="{ disabled }"
    />
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
