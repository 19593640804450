<template>
  <v-card
    class="pa-3"
  >
    <div class="mb-2" style="font-size: 16px">
      <strong>Summary</strong>
    </div>
    <div>
      <strong>{{ codePreview }}</strong>
    </div>
    <ul>
      <li>Available on online channels</li>
      <li
        v-if="value && value.once_per_customer"
      >One use per customer</li>
      <li
        v-if="value && value.assign_to_customer"
      >Assigned to Contact</li>
      <li
        v-if="value && value.type === 'AMOUNT' && value.allocation_method === 'each'"
      >Applies to each eligible item per order</li>
      <li
        v-else
      >Applies once per order</li>
    </ul>
    <div
      class="mt-4"
    >
      <strong>Can not combine with other automatic discounts</strong>
    </div>
    <p
      class="mb-0"
    >Customers will not be able to enter a code if an automatic discount is already applied at checkout.</p>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  computed: {
    codePreview () {
      return `${this.value?.code_prefix || ''}1234EXAMPLE`.toUpperCase()
    }
  }
}
</script>
